<template>
    <div class="vacancy-card" @click="goTo(`/templates/${vacancy.id}`)">
        <div @click.stop="goTo(`/templates/${vacancy.id}/edit`)" class="vacancy-card__edit-btn" >
            <img src="@/assets/images/icons/pencil-black.svg" alt="edit">
        </div>
        <p class="vacancy-card__date">Создан {{ vacancy.date }}</p>
        <h3 class="vacancy-card__title">{{ vacancy.title ? vacancy.title : 'Без названия' }}</h3>

        <div class="vacancy-card__responses"  @click.stop="createVacancyFromTemplate">
            <p class="vacancy-card__responses-text">
                Создать вакансию
            </p>
        </div>
    </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router"

const router = useRouter()
const route = useRoute()

const props = defineProps({
    vacancy: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['createVacancy'])
const createVacancyFromTemplate = () => {
    emit('createVacancy',props.vacancy.id)
}
const goTo = (path) => {
  router.push({path})
}
</script>

<style scoped lang="scss">
.vacancy-card {
    position: relative;
    height: 153px;
    padding: 18px 16px 16px 16px;
    border-radius: 16px;
    background-color: #fff;
    color: $dark;
    text-decoration: none;
    transition: .3s;
    box-shadow: 2px 2px 8px 0px #0000000A;
    cursor: pointer;


    &:hover {
        box-shadow: 2px 2px 24px 0px #1E39591F;
    }

    &:hover > .vacancy-card__edit-btn {
        display: flex;
    }

    &__date {
        color: $gray;
        font-size: 12px;
    }

    &__title {
        height: 34px;
        margin-top: 6px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: $dark;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        overflow: hidden;
    }

    &__responses {
        width: 100%;
        height: 48px;
        margin-top: 16px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light;
        color: $dark;
        text-decoration: none;
        transition: .3s;

        &:hover {
            background-color: $light-gray-1;
        }

        &:active {
            background-color: $light-gray-2;
        }
    }

    &__responses-text {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    &__responses-text-num {
        color: $blue;
    }

    &__edit-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light;
        transition: .3s;
        cursor: pointer;
        display: none;
        z-index: +1;

        &:hover {
            background-color: $light-gray-1;
        }

        &:active {
            background-color: $light-gray-2;
        }
    }
}
</style>
