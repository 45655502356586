<template>
  <div class="templates">
    <MainHeader v-if="templatesList.vacancies" class="templates__header" title="Шаблоны"
      :number="templatesList.vacancies.length ? templatesList.vacancies_count : ''" btnText="Новый шаблон"
      @btnClick="$router.push('/templates/new')" />

    <div class="templates__content" v-if="!errorGet && templatesList.vacancies">
      <div class="templates__cards-wrapper" v-if="templatesList.vacancies.length">
        <div class="templates__cards">
          <TemplateCard class="templates__card" v-for="vacancy in templatesList.vacancies" :key="vacancy.id"
            :vacancy="vacancy" @createVacancy="onCreateVacancy" />
        </div>
      </div>

      <div class="templates__no-content" v-if="!templatesList.vacancies.length">
        <VNoContent title="Список шаблонов пуст" description="Вы еще не добавили шаблоны" />
      </div>
      <div
          class="templates__pagination"
          v-if="templatesList.pages_count > 1 && templatesList.vacancies.length"
      >
        <VButton
            class="templates__pagination-btn"
            label="Загрузить еще"
            bg="#0D5FCB1A"
            color="#0086B2"
            bgHover="#0D5FCB4D"
            colorHover="#0086B2"
            @click="loadMore()"
            v-if="page < templatesList.pages_count && showPagination"
        />
        <VPaginate
            :pageCount="templatesList.pages_count"
            :modelValue="page"
            @update:modelValue="pagination"
            class="templates__pagination-pag"
        />
      </div>
    </div>
  </div>
  <AccessClosed v-if="templatesList.blocked_by_contract" />
</template>

<script setup>
import { onMounted, onActivated, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import vacancies from "@/api/vacancies";
import MainHeader from "@/components/MainHeader";
import TemplateCard from "@/components/TemplateCard";
import AccessClosed from "@/components/AccessClosed"
import VPaginate from "@/components/UI/VPaginate/index.vue";

const router = useRouter()
const route = useRoute()
const store = useStore();
const errorGet = ref(false)
const templatesList = ref({})
const vacancyId = ref(route.params.id);
const vacancy = ref({});
const vacanciesList = ref({})
const page = ref(1);
const showPagination = ref(true)

const getTemplates = async () => {
  try {
    templatesList.value = await vacancies.getVacancies({
      is_template: 1
    })
  } catch (error) {
    errorGet.value = true
    console.log(error)
  }
}

const getVacancy = async () => {
  try {
    vacancy.value = await vacancies.getVacancy(vacancyId.value);
  } catch (error) {
    console.log(error);
  }
};

const createVacancy = async () => {
  try {
    await vacancies.createVacancies(vacancyId.value);
    await router.push("/vacancies");
  } catch (error) {
    showPopupErrorModal.value = true;
    console.log(error);
  }
};

const getCreateVacancies = async () => {
  try {
    const response = await vacancies.getCreateVacancies();
    vacancyId.value = response.id;
    return response
  } catch (error) {
    console.log(error);
  }
};


const onCreateVacancy = async (templateId) => {
  localStorage.setItem('templateId', templateId)
  router.push({
    path: `/templates/${templateId}/create`,
  })
}

onActivated(() => {
  if (route.query.update) {
    router.push('/templates')
    getTemplates()
  }
})

const loadMore = async (num) => {
  showPagination.value = false
  page.value = num ? num : page.value + 1;

  const params = {
    page: page.value,
    is_template: 1
  };

  try {
    const response = await vacancies.getVacancies(params)

    if (num) templatesList.value.vacancies = [];
    templatesList.value.vacancies.push(...response.vacancies);
    showPagination.value = true
  } catch (error) {
    errorGet.value = true
    console.log(error);
  }
};

const pagination = (val) => loadMore(val);

onMounted(() => getTemplates())
</script>

<style scoped lang="scss">
.templates {
  padding-bottom: 30px;

  &__header {
    margin-top: 32px;
  }

  &__content {
    margin-top: 24px;
  }

  &__cards-wrapper {
    min-height: calc(100vh - 255px);
  }

  &__cards {
    margin-left: -32px;
    margin-top: -24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    width: calc(33.3% - 32px);
    margin-top: 24px;
    margin-left: 32px;
  }

  &__pagination {
    position: relative;
    width: 100%;
    height: 48px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  &__pagination-btn {
    width: 156px;
    padding: 0 10px;
    font-weight: 400;
    border-radius: 8px;

    &:active {
      background-color: #0D5FCB80 !important;
    }
  }

  &__pagination-pag {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__no-content {
    width: 100%;
    height: calc(100vh - 143px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
